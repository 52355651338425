var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.edit($event)}}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.users_list,"item-text":"username","item-value":"id","error-messages":errors,"name":"Użytkownik","label":"Użytkownik","data-vv-name":"username","required":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"type":"password","min-length":"6","error-messages":errors,"label":"Nowe hasło","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"confirm","rules":"required|password:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","min-length":"6","required":"","error-messages":errors,"label":"Powtórz hasło"},model:{value:(_vm.password_repeat),callback:function ($$v) {_vm.password_repeat=$$v},expression:"password_repeat"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.user_groups_list,"item-text":"name","item-value":"id","error-messages":errors,"name":"Grupa","label":"Grupa","data-vv-name":"group","required":""},model:{value:(_vm.user_group),callback:function ($$v) {_vm.user_group=$$v},expression:"user_group"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Zapisz ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }